<script>

import moment from 'moment'

export default {
  name: 'processGameStats',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    getGoalieAttribution(goalieStats, outcome) {
      if (Object.entries(goalieStats).length === 1) {
        return Object.keys(goalieStats)[0]
      } else {
        // If game was a win, attribute to goalie with fewer goals against
        let mostGa = 0
        let mostGaPlayer
        let leastGaPlayer
        let leastGa = 100

        for (const [jerseyNumber, goalie] of Object.entries(goalieStats)) {
          if (goalie.ga > mostGa) {
            mostGa = goalie.ga
            mostGaPlayer = jerseyNumber
          }
          if (goalie.ga < leastGa) {
            leastGa = goalie.ga
            leastGaPlayer = jerseyNumber
          }
        }

        if (outcome === 'W') {
          return leastGaPlayer
        } else {
          return mostGaPlayer
        }
      }
    },
    processTeamStats (team, tsOpponentId) {

      let players = team.players
      let games = team.games
      let teamStats = []
      let playerStats = []
      let goalieStats = {}
      let statsList = []
      let summary = []
      let gameCounter = {}
      let pastGames = []
      let futureGames = []
      let record = {}
      let recordLine = []
      let opponentName = ''
      this.missingJerseyNumber = false
      this.missingGoalie = true

      let percentageStats = []
      let customStats = []

      team.statCategories.forEach(stat => {
        if (stat.type === '%') {
          percentageStats.push(stat.name)
        }
        if (!stat.default) {
          customStats.push(stat.name)
        }
      })

      let statsByOutcome = {
        'total': [],
        'wins': [],
        'losses': [],
        'ties': []
      }

      record = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        ties: 0,
        goalsFor: 0,
        goalsAgainst: 0
      }

      let num = 0

      players.forEach(player => {

        // For players without jersey numbers, add a default, and store error state
        if (!player.jerseyNumber) {
          player.jerseyNumber = num
          this.missingJerseyNumber = true
          player.missingJerseyNumber = true
          player._cellVariants = { jerseyNumber: 'warning' }
        }
        // For players without jersey numbers, add a default, and store error state
        if (!player.position) {
          player.position = '?'
          this.missingPlayerPosition = true
          player.missingPlayerPosition = true
        }
        if (player.position.toLowerCase() === 'goalie' || player.position.toLowerCase() === 'goaltender') {
          this.missingGoalie = false
          player.shots = 0
          player.ga = 0
          player.svperc = 0
          player.so = 0
          player.gp = 0
          player.gaa = 0
          player.wins = 0
          player.losses = 0
          player.winperc = 0
          goalieStats[player.jerseyNumber] = player
        }
        player.name = player.firstName.charAt(0) + '. ' + player.lastName
        player.goals = 0
        player.assists = 0
        player.points = 0
        player.plusminus = 0
        player.pim = 0
        player.shg = 0
        player.ppg = 0
        playerStats[player.jerseyNumber] = player
        num++
      })

      games.forEach(game => {
        // If request is for specific opponent, check for that opponent
        if (game.tsOpponentId == tsOpponentId || tsOpponentId == null) {
          if (tsOpponentId) {
            opponentName = game.opponentName
          }
          if ('gameScore' in game) {
            if (typeof game.gameScore != 'undefined' && game.gameScore !== 'undefined' && game.gameScore.length !== 0) {
              teamStats.push(game.teamStats.t)
              for (var stat in game.teamStats.t[0]) {
                if (!statsList.includes(stat)) {
                  statsList.push(stat)
                }
              }
              statsByOutcome.total.push(game.teamStats.t)
              if (game.gameScore[0].t > game.gameScore[1].t) {
                record.wins += 1
                game.outcome = 'W'
                statsByOutcome.wins.push(game.teamStats.t)
                // There might have been more than one goalie, so figure out who should get the attribution
                let goalieJersey = this.getGoalieAttribution(game.goalieStats, 'W')
                goalieStats[parseInt(goalieJersey)].wins += 1
              } else if (game.gameScore[0].t < game.gameScore[1].t) {
                record.losses += 1
                game.outcome = 'L'
                statsByOutcome.losses.push(game.teamStats.t)
                // There might have been more than one goalie, so figure out who should get the attribution
                let goalieJersey = this.getGoalieAttribution(game.goalieStats, 'L')
                goalieStats[parseInt(goalieJersey)].losses += 1
              } else {
                record.ties += 1
                game.outcome = 'T'
                statsByOutcome.ties.push(game.teamStats.t)
              }
              record.gamesPlayed += 1
              record.goalsFor += game.gameScore[0].t
              game.gf = game.gameScore[0].t
              record.goalsAgainst += game.gameScore[1].t
              game.ga = game.gameScore[1].t
              for (const [jerseyNumber, goalie] of Object.entries(goalieStats)) {
                if (goalie.shots > 0) {
                  goalieStats[jerseyNumber]['ga'] += goalie.ga
                  goalieStats[jerseyNumber]['shots'] += goalie.shots
                  goalieStats[jerseyNumber]['gp'] += 1
                  if (game.teamStats.t[1].goals === 0) {
                    goalieStats[jerseyNumber]['so'] += 1
                  }
                }
              }
              game.playerStats.forEach(player => {
                playerStats[parseInt(player.jerseyNumber)]['goals'] += player.goals
                playerStats[parseInt(player.jerseyNumber)]['assists'] += player.assists
                playerStats[parseInt(player.jerseyNumber)]['points'] += player.points
                playerStats[parseInt(player.jerseyNumber)]['plusminus'] += player.plusminus
                playerStats[parseInt(player.jerseyNumber)]['pim'] += player.pim
                playerStats[parseInt(player.jerseyNumber)]['shg'] += player.shg
                playerStats[parseInt(player.jerseyNumber)]['ppg'] += player.ppg
              })
            }
          }
        }
        if (moment(game.startDate.toDate()).isBefore()) {
          pastGames.push(game)
        } else {
          futureGames.push(game)
        }
      })

      // Build the stat widgets object
      let statWidgets = {}

      // Initialize the widgets object

      statsList.forEach((statName) => {
        statWidgets[statName] = {
          name: statName,
          total: {
            us: {
              count: 0,
              perc: 0,
              avg: 0
            },
            them: {
              count: 0,
              perc: 0,
              avg: 0
            }
          },
          wins: {
            us: {
              count: 0,
              perc: 0,
              avg: 0
            },
            them: {
              count: 0,
              perc: 0,
              avg: 0
            }
          },
          losses: {
            us: {
              count: 0,
              perc: 0,
              avg: 0
            },
            them: {
              count: 0,
              perc: 0,
              avg: 0
            }
          },
          ties: {
            us: {
              count: 0,
              perc: 0,
              avg: 0
            },
            them: {
              count: 0,
              perc: 0,
              avg: 0
            }
          }
        }
      })

      for (var outcome in statsByOutcome) {
        statsByOutcome[outcome].forEach((game, gameIndex) => {
          for (var stat in game[0]) {
            statWidgets[stat][outcome].us.count += Math.round(game[0][stat],0)
            statWidgets[stat][outcome].them.count += Math.round(statsByOutcome[outcome][gameIndex][1][stat],0)
            statWidgets[stat][outcome].us.perc = statWidgets[stat][outcome].us.count / (statWidgets[stat][outcome].us.count + statWidgets[stat][outcome].them.count)
            statWidgets[stat][outcome].them.perc = statWidgets[stat][outcome].them.count / (statWidgets[stat][outcome].us.count + statWidgets[stat][outcome].them.count)
            statWidgets[stat][outcome].us.avg = Math.round(statWidgets[stat][outcome].us.count / (gameIndex + 1),0)
            statWidgets[stat][outcome].them.avg = Math.round(statWidgets[stat][outcome].them.count / (gameIndex + 1),0)
          }
        })
      }

      // Filter perc statWidgets

      let remove = ['team', 'powerplays', 'shorthandedgoals', 'powerplayPerf', 'powerplayPerfPerc', 'battlesPerc', 'faceoffsPerc']

      // let remove = ['team']

      this.statWidgets = {}
      this.percWidgets = {}

      for (var stat in statWidgets) {
        if (remove.indexOf(statWidgets[stat].name) === -1) {
          this.statWidgets[stat] = statWidgets[stat]
        } else {
          this.percWidgets[stat] = statWidgets[stat]
        }
      }

      // // Summarize all team stats

      summary[0] = {}
      summary[1] = {}

      statsList.forEach((stat) => {
        summary[0][stat] = 0
        summary[1][stat] = 0
        gameCounter[stat] = 1
      })

      // We want to check custom stats, and if they are both zero, don't count that game for per-game average
      // This is to support adding stat category after first game

      teamStats.forEach((gameStats) => {
        gameStats.forEach((stats, i) => {
          for (var stat in stats) {
            if (Number.isInteger(stats[stat])) {
              summary[i][stat] += stats[stat]
              // If one of the teams has a non-zero CUSTOM stat, include in the total games average for this stat
              if (i === 1) {
                if (customStats.includes(stat)) {
                  if (summary[0][stat] > 0 || summary[1][stat] > 0) {
                    gameCounter[stat]++
                  }
                } else {
                  gameCounter[stat]++
                }
              }
              if (stat !== 'powerplaygoals') {
                summary[i][stat + 'pga'] = summary[i][stat] / gameCounter[stat]
              }
            }
          }
        })
      })

      percentageStats.forEach((stat) => {
        summary[0][stat + 'Perc'] = summary[0][stat] / (summary[1][stat] + summary[0][stat])
        summary[1][stat + 'Perc'] = summary[1][stat] / (summary[1][stat] + summary[0][stat])
      })

      summary[0]['powerplayPerf'] = summary[0].powerplaygoals / summary[0].powerplays
      summary[1]['powerplayPerf'] = summary[1].powerplaygoals / summary[1].powerplays

      summary[0]['team'] = team.name
      summary[1]['team'] = 'Opponent'

      // save and win pct for goalies

      for (const [jerseyNumber, goalie] of Object.entries(goalieStats)) {
        if (goalie.gp > 0) {
          goalieStats[jerseyNumber]['svperc'] = ((goalieStats[jerseyNumber]['shots'] - goalieStats[jerseyNumber]['ga']) / goalieStats[jerseyNumber]['shots']).toFixed(3)
          goalieStats[jerseyNumber]['gaa'] = (goalieStats[jerseyNumber]['ga'] / goalieStats[jerseyNumber]['gp']).toFixed(2)
          goalieStats[jerseyNumber]['winperc'] = (goalieStats[jerseyNumber]['wins'] / goalieStats[jerseyNumber]['gp']).toFixed(3)
        }
      }

      let winPerc = record.wins / (record.wins + record.losses)

      record['winPerc'] = winPerc.toFixed(3)

      recordLine[0] = record

      // Replace unknown player numbers with a blank

      playerStats.forEach((player) => {
        if (player.missingJerseyNumber) {
          player.jerseyNumber = 0
        }
      })

      // Create a record for unknown goalie

      if (this.missingGoalie) {
        goalieStats[0] = {
          jerseyNumber: '',
          name: 'Please add at least one goalie in TeamSnap',
          _rowVariant: 'warning'
        }
      }

      let allStats = {
        recordLine: recordLine,
        summary: summary,
        teamStats: teamStats,
        playerStats: playerStats,
        goalieStats: goalieStats,
        record: record,
        pastGames: pastGames,
        futureGames: futureGames,
        mostRecentGame: [pastGames[pastGames.length - 1]],
        opponentName: opponentName
      }

      return allStats
    }
  }
}

</script>
