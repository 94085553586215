<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <div v-if="showTeam">
      <b-row>
        <b-col>
          <h2 class='team-title'>{{ team.name }} <span v-if="stats.opponentName">v. {{ stats.opponentName }}</span>
            <span v-if="isManager" class="float-right">
              <b-dropdown id="sync" text="Admin " size="sm" right>
                <b-dropdown-item @click.stop="teamSettings(team.id)">Team Settings</b-dropdown-item>
                <b-dropdown-item @click.stop="updateTeamData(team.tsId)">Sync TeamSnap</b-dropdown-item>
                <b-dropdown-item @click.stop="recalcAllGames()">Recalc All Games</b-dropdown-item>
              </b-dropdown>
            </span>
          </h2>
        </b-col>
      </b-row>
      <b-alert variant="warning" :show="missingJerseyNumber">Some of your players are missing jersey numbers.  Please update in TeamSnap and then re-sync.</b-alert>
            <b-alert variant="warning" :show="missingGoalie">Looks like you don't have a goalie.  Please update in TeamSnap and then re-sync.</b-alert>
      <b-row>
        <b-col>
          <b-card title='Record'
                  class='card-mobile card-mobile-condensed'>
            <b-table v-if="stats.recordLine[0].gamesPlayed > 0"
                    responsive striped
                    :items='stats.recordLine'
                    :fields='recordFields'
                    :per-page='1'
                    :current-page='1'
                    :sort-by.sync='sorting.pastGames.sortBy'
                    :sort-desc.sync='sorting.pastGames.desc'
                    :sort-direction='sorting.pastGames.direction'>
            </b-table>
            <h6 v-if="stats.recordLine[0].gamesPlayed === 0" class="text-center">Your record will be summarized here after the first game is logged!<br/><br/><b-button variant="info" @click.stop="scrollTo('futureGames')" href="">Get Started</b-button></h6>
          </b-card>
        </b-col>
        <b-col>
          <b-card title='Last Game' class='card-mobile card-mobile-condensed'>
            <b-table responsive striped hover
                    :items='stats.pastGames'
                    :fields='mostRecentGameFields'
                    :per-page='1'
                    :current-page='1'
                    :sort-by.sync='sorting.pastGames.sortBy'
                    :sort-desc.sync='sorting.pastGames.desc'
                    :sort-direction='sorting.pastGames.direction'
                    @row-clicked='onPastGameRowClicked'>
            </b-table>
          </b-card>
        </b-col>
        <b-col>
          <b-card title='Next Game' class='card-mobile card-mobile-condensed'>
            <b-table responsive striped hover
                    :items='stats.futureGames'
                    :fields='nextGameFields'
                    :per-page='1'
                    :current-page='1'
                    :sort-by.sync='sorting.nextGame.sortBy'
                    :sort-desc.sync='sorting.nextGame.desc'
                    :sort-direction='sorting.nextGame.direction'
                    @row-clicked='onPastGameRowClicked'>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col v-for='stat in percWidgets'
               :key='stat.index'
               cols="3" >
          <b-card :title="stat.name" class="stat-widget">
          </b-card>
        </b-col>
      </b-row> -->

      <b-row>
        <b-col>
          <b-card title='Team Summary'
            class='per-game-average card-mobile card-mobile-condensed'>
            <b-table v-if="stats.recordLine[0].gamesPlayed > 0" :items='stats.summary' :fields='summaryFields' responsive></b-table>
            <h6 v-if="stats.recordLine[0].gamesPlayed === 0" class="text-center">Your stats will be summarized here after the first game is logged!<br/><br/><b-button variant="info" @click.stop="scrollTo('futureGames')" href="">Get Started</b-button></h6>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
            <b-card v-b-toggle.collapse-1 title="Stats by Period" >
              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button> 
            </b-card>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <b-card v-b-toggle.collapse-1 title="Stats by Period" @click="toggleShowHide(showHide)" ><span class="show-hide">{{ showHide }}</span>
            <hr/>
            <b-collapse id="collapse-1" class="mt-2">
              <b-row>
              <b-col md="4" xl="3"
                    v-for='stat in statWidgets'
                    :key='stat.index'>
                <b-card :title="stat.name" class="stat-widget">
                  <span class='stat-breakdown-title'>All Games ({{ stat.total.us.avg }} / {{ stat.total.them.avg }})</span>
                  <b-progress class="mt-1" :max="max">
                    <b-progress-bar v-if="stat.total.us.perc > 0 || stat.total.them.perc > 0" :value="stat.total.us.perc" :label="stat.total.us.count.toString()" variant="primary"></b-progress-bar>
                    <b-progress-bar v-if="stat.total.us.perc > 0 || stat.total.them.perc > 0" :value="stat.total.them.perc" :label="stat.total.them.count.toString()" variant="danger"></b-progress-bar>
                  </b-progress>
                  <span class='stat-breakdown-title'>Wins ({{ stat.wins.us.avg }} / {{ stat.wins.them.avg }})</span>
                  <b-progress class="mt-1" :max="max">
                    <b-progress-bar v-if="stat.wins.us.perc > 0 || stat.wins.them.perc > 0" :value="stat.wins.us.perc" :label="stat.wins.us.count.toString()" variant="primary"></b-progress-bar>
                    <b-progress-bar v-if="stat.wins.us.perc > 0 || stat.wins.them.perc > 0" :value="stat.wins.them.perc" :label="stat.wins.them.count.toString()" variant="danger"></b-progress-bar>
                  </b-progress>
                  <span class='stat-breakdown-title'>Losses ({{ stat.losses.us.avg }} / {{ stat.losses.them.avg }})</span>
                  <b-progress class="mt-1" :max="max">
                    <b-progress-bar v-if="stat.losses.us.perc > 0 || stat.losses.them.perc > 0" :value="stat.losses.us.perc" :label="stat.losses.us.count.toString()" variant="primary"></b-progress-bar>
                    <b-progress-bar v-if="stat.losses.us.perc > 0 || stat.losses.them.perc > 0" :value="stat.losses.them.perc" :label="stat.losses.them.count.toString()" variant="danger"></b-progress-bar>
                  </b-progress>
                  <span class='stat-breakdown-title'>Ties ({{ stat.ties.us.avg }} / {{ stat.ties.them.avg }})</span>
                  <b-progress class="mt-1" :max="max">
                    <b-progress-bar v-if="stat.ties.us.perc > 0 || stat.ties.them.perc > 0" :value="stat.ties.us.perc" :label="stat.ties.us.count.toString()" variant="primary"></b-progress-bar>
                    <b-progress-bar v-if="stat.ties.us.perc > 0 || stat.ties.them.perc > 0" :value="stat.ties.them.perc" :label="stat.ties.them.count.toString()" variant="danger"></b-progress-bar>
                  </b-progress>
                </b-card>
              </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card title='Players' class='card-mobile card-mobile-condensed'>
            <b-alert variant="warning" :show="missingJerseyNumber">Some of your players are missing jersey numbers.  Please update in TeamSnap and then re-sync.</b-alert>
            <b-alert variant="warning" :show="missingGoalie">Looks like you don't have a goalie.  Please update in TeamSnap and then re-sync.</b-alert>
            <b-table responsive striped
                    :items='stats.playerStats'
                    :fields='playerFields'
                    :sort-by.sync='sorting.players.sortBy'
                    :sort-desc.sync='sorting.players.desc'
                    :sort-direction='sorting.players.direction'>
              <span slot="img" slot-scope="data" v-html="data.value"></span>
            </b-table>
            <b-table responsive striped
                    :items='goalieStatsArr'
                    :fields='goalieStatsFields'
                    :sort-by.sync='sorting.goalies.sortBy'
                    :sort-desc.sync='sorting.goalies.desc'
                    :sort-direction='sorting.goalies.direction'></b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card v-if="stats.pastGames.length > 0" title='Past Games' class='card-mobile card-mobile-condensed'>
            <b-table responsive striped hover
                    :items='stats.pastGames'
                    :fields='pastGameFields'
                    :sort-by.sync='sorting.pastGames.sortBy'
                    :sort-desc.sync='sorting.pastGames.desc'
                    :sort-direction='sorting.pastGames.direction'
                    @row-clicked='onPastGameRowClicked'>
              <template v-if="isManager" #cell(actions)="row">
                <b-btn v-if="Object.entries(row.item.goalieStats).length > 0" size="sm" @click.stop="add(row.item.id)" variant="primary" >Edit Stats</b-btn>
                <b-btn v-if="Object.entries(row.item.goalieStats).length == 0" size="sm" @click.stop="add(row.item.id)" >Add Stats</b-btn>
              </template>
              <template #cell(opponentName)="row">
                <!-- <b-link @click.stop="goToOpponent(row.item.tsOpponentId)">{{ row.item.opponentName }}</b-link> -->
                {{ row.item.opponentName }}
              </template>
            </b-table>
          </b-card>
          <b-card id="futureGames" title='Future Games' class='card-mobile card-mobile-condensed'>
            <b-table responsive striped hover
                    :items='stats.futureGames'
                    :fields='futureGameFields'
                    :sort-by.sync='sorting.futureGames.sortBy'
                    :sort-desc.sync='sorting.futureGames.desc'
                    :sort-direction='sorting.futureGames.direction'
                    @row-clicked='onFutureGameRowClicked'>
              <template v-if="isManager" #cell(actions)="row">
                <b-btn size="sm" @click.stop="live(row.item.id)">Live!</b-btn>
                <b-btn size="sm" @click.stop="add(row.item.id)">Add Stats</b-btn>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!isMember && !isManager && !loading">
      <b-col cols="12">
        <b-card>
            <p>You need a team manager to link your team.  Shoot them an email with a link to this page and we'll have you set up in a jiffy.</p>
        </b-card>
      </b-col>
    </b-row>
    <ul v-if="errors && errors.length">
      <li v-for="error of errors"
          :key="error.id">
        {{error.message}}
      </li>
    </ul>
  </b-container>
</div>
</template>

<script>

import moment from 'moment'
import TeamSnap from '@/mixins/TeamSnap'
import { store } from '../store'
import ProcessGameStats from '@/mixins/ProcessGameStats'
import ProcessTeamStats from '@/mixins/ProcessTeamStats'

export default {
  name: 'TeamDetail',
  mixins: [TeamSnap, ProcessTeamStats, ProcessGameStats],
  data () {
    return {
      loading: true,
      showHide: 'show',
      teamName: '',
      stats: {},
      statWidgets: {},
      max: 1,
      sorting: {},
      recordFields: [
        { key: 'gamesPlayed', label: 'GP', 'class': 'text-center' },
        { key: 'wins', label: 'Wins', 'class': 'text-center' },
        { key: 'losses', label: 'Losses', 'class': 'text-center' },
        { key: 'ties', label: 'Ties', 'class': 'text-center' },
        { key: 'winPerc', label: '%','class': 'text-center' }
      ],
      missingJerseyNumber: false,
      missingGoalie: false,
      playerFields: [
        { key: 'jerseyNumber', label: '#', 'class': 'text-left' },
        { key: 'name', label: 'Name', 'class': 'text-left text-nowrap' },
        { key: 'goals', label: 'G', sortable: true, 'class': 'text-center' },
        { key: 'assists', label: 'A', sortable: true, 'class': 'text-center' },
        { key: 'points', label: 'P', sortable: true, 'class': 'text-center' },
        { key: 'plusminus', label: '+/-', sortable: true, 'class': 'text-center' },
        { key: 'ppg', label: 'PPG', sortable: true, 'class': 'text-center' },
        { key: 'shg', label: 'SHG', sortable: true, 'class': 'text-center' },
        { key: 'pim', label: 'PIM', sortable: true, 'class': 'text-center' }
      ],
      goalieStatsFields: [
        { key: 'jerseyNumber', label: '#', 'class': 'text-left' },
        { key: 'name', label: 'Goalies', 'class': 'text-left text-nowrap' },
        { key: 'gp', label: 'GP', 'class': 'text-center' },
        { key: 'wins', label: 'W', 'class': 'text-center' },
        { key: 'losses', label: 'L', 'class': 'text-center' },
        { key: 'winperc', label: 'WIN%', 'class': 'text-center' },
        { key: 'shots', label: 'Shots', 'class': 'text-center' },
        { key: 'ga', label: 'GA', 'class': 'text-center' },
        { key: 'gaa', label: 'GAA', 'class': 'text-center'},
        { key: 'svperc', label: 'SV%','class': 'text-center'},
        { key: 'so', label: 'SO', 'class': 'text-center' },
        { key: 'pim', label: 'PIM', 'class': 'text-center' }
      ],
      summaryFields: [],
      teamStatsFields: [],
      nextGameFields: [
        { key: 'startDate', label: 'Date', 'class': 'text-left text-nowrap',
          formatter: (value) => {
            return moment(value.toDate()).format('MMM D, YYYY')
          }
        },
        { key: 'opponentName', label: 'Opponent', 'class': 'text-left text-nowrap' },
        { key: 'location', label: 'Location', 'class': 'text-left text-nowrap' },
      ],
      mostRecentGameFields: [
        { key: 'startDate', label: 'Date', 'class': 'text-left text-nowrap',
          formatter: (value) => {
            return moment(value.toDate()).format('MMM D, YYYY')
          }
        },
        { key: 'opponentName', label: 'Opponent', 'class': 'text-left text-nowrap' },
        { key: 'outcome', label: 'W/L/T', 'class': 'text-left text-nowrap' },
        { key: 'gf', label: 'GF', 'class': 'text-left text-nowrap' },
        { key: 'ga', label: 'GA', 'class': 'text-left text-nowrap' }
      ],
      pastGameFields: [
        { key: 'startDate', label: 'Date', sortable: true, 'class': 'text-left text-nowrap',
          formatter: (value) => {
            return moment(value.toDate()).format('MMM D, YYYY')
          }
        },
        { key: 'opponentName', label: 'Opponent', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'gameLabel', label: 'Label', sortable: true, 'class': 'text-left text-nowrap d-none d-md-table-cell' },
        { key: 'outcome', label: 'W/L/T', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'gf', label: 'GF', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'ga', label: 'GA', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'actions', label: 'Action', 'class': 'text-center d-none d-md-table-cell' }
      ],
      futureGameFields: [
        { key: 'startDate', label: 'Date', sortable: true, 'class': 'text-left text-nowrap',
          formatter: (value) => {
            return moment(value.toDate()).format('MMM D, YYYY')
          }
        },
        { key: 'opponentName', label: 'Opponent', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'gameType', label: 'H/A', sortable: true, 'class': 'text-left text-nowrap' },
        { key: 'gameLabel', label: 'Label', sortable: true, 'class': 'text-left text-nowrap d-none d-lg-table-cell' },
        { key: 'actions', label: 'Action', 'class': 'text-center d-none d-md-table-cell' }
      ],
      players: [],
      isMember: false,
      isManager: false,
      teamExists: false,
      showTeam: false,
      showSignup: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      tsResult: '',
      errors: [],
      backLink: '/',
      teamId: '',
      doReload: 0
    }
  },
  head: {
    title () {
      return {
        inner: this.teamName
      }
    },
    meta () {
      return [
        { name: 'description', content: this.teamName, id: 'desc' }
      ]
    }
  },
  computed: {
    goalieStatsArr: function () {
      console.log(this.team)
      let arr = []
      for (const stats of Object.values(this.stats.goalieStats)) {
        arr.push(stats)
      }
      return arr
    },
  },
  async created () {
    this.doReload = parseInt(localStorage.getItem('doReload'))
    let teamId = this.$route.params.teamId
    let opponentId = parseInt(this.$route.params.opponentId) || null
    this.userProfile = store.state.userProfile
    if (this.userProfile.teams.includes(teamId)) {
      this.isMember = true
      if (this.userProfile.manages.includes(teamId)) {
        this.isManager = true
      }
      await store.commit('setCurrentTeamId', teamId)
      await store.dispatch('bindTeamData')
      this.team = store.state.team
      this.teamName = this.team.name
      this.team.games = await store.dispatch('getGamesByTeamId', this.team.id)
      console.log(this.team)
      if (this.team.name) {
        this.setNav()
        if (this.doReload === 1) {
          this.updateTeamData()
        } else {
          this.stats = this.processTeamStats(this.team, opponentId)
          // this.stats = this.formatStats(stats)
          this.buildSummaryFields()
          this.teamExists = true
          this.showTeam = true
          this.loading = false
          this.$emit('updateHead')
        }
      } else {
        this.showteam = false;
        this.teamExists = false;
        this.loading = false;
      }
    } else {
      this.isMember = false
      this.loading = false
      console.log('Not a member')
    }
    this.sorting = {
      players: {
        sortBy: 'name',
        desc: false,
        direction: 'desc'
      },
      goalies: {
        sortBy: 'shots',
        desc: true,
        direction: 'desc'
      },
      futureGames: {
        sortBy: 'startDate',
        desc: false,
        direction: 'asc'
      },
      pastGames: {
        sortBy: 'startDate',
        desc: true,
        direction: 'desc'
      },
      nextGame: {
        sortBy: 'startDate',
        desc: false,
        direction: 'desc'
      }
    }
  },
  methods: {
    setNav () {
      store.commit('updateNav', {
        backLink: '/teamlist',
        teamLink: '',
        teamName: ''
      })
    },
    toggleShowHide (text) {
      if (text == 'show') {
        this.showHide = 'hide'
      } else {
        this.showHide = 'show'
      }
    },
    removeQueryString () {
      let query = {}
      this.$router.replace({ query });
    },
    formatStats (stats) {
      stats.summary.forEach(teamStats => {
        // Get all the stats that are pga
        for (const key in teamStats) {
          if (key.indexOf('pga') !== -1) {
            // get companion total
            let companionKey = key.slice(0, -3)
            let total = teamStats[companionKey]
            teamStats[companionKey] = total + ' <small>(' + Math.round(teamStats[key], 1) + ')</small>'
          }
        }
      })
      return stats
    },
    buildSummaryFields () {

      let summaryFields = {}

      summaryFields = [
        { key: 'team', label: '', 'class': 'text-left text-nowrap',
          formatter: (value, key, item) => {
            return item.team.split(" ").shift();
          }
        },
        { key: 'goals', label: 'GF',
          'class': 'text-center text-nowrap'
        },
        { key: 'shots', label: 'SOG',
          'class': 'text-center text-nowrap'
        },
        { key: 'shotPerc', label: 'SHOT%',
          'class': 'text-center',
          formatter: (value, key, item) => {
            return Math.round(item.goals / item.shots * 100) + '%'
          }
        },
        { key: 'powerplaygoals', label: 'PPG',
          'class': 'text-center text-nowrap'
        },
        { key: 'powerplayPerf', label: 'PP%',
          'class': 'text-center text-nowrap',
          formatter: (value) => {
            return Math.round(value * 100) + '%'
          }
        },
        { key: 'shorthandedgoals', label: 'SHG',
          'class': 'text-center'
        },
        { key: 'penalties', label: 'PEN',
          'class': 'text-center text-nowrap'
        }
      ]

      this.statCategories = JSON.parse(JSON.stringify(this.team.statCategories));

      let additionalStats = [
        {
          default: true,
          name: 'powerplaygoals',
          shortName: 'PPG',
          type: 'total'
        },
        {
          default: true,
          name: 'powerplayPerf',
          shortName: 'PP%',
          type: '%'
        },
        {
          default: true,
          name: 'shorthandedgoals',
          shortName: 'SHG',
          type: 'total'
        },
        {
          default: true,
          name: 'penalties',
          shortName: 'PEN',
          type: 'total'
        }
      ]

      additionalStats.forEach(stat => {
        this.statCategories.push(stat)
      })

      this.summaryFields = summaryFields

    },
    teamSettings () {
      this.$router.push({
        name: 'teamsettings',
        params: { teamId: this.team.id }
      })
    },
    recalcAllGames () {
      let gameUpdates = []
      this.team.games.forEach((game) => {
        if (game.gameScore !== 'undefined' && game.gameScore.length > 0) {
          gameUpdates.push(this.processGameStats(game.tsGameId))
        }
      })
      Promise.all(gameUpdates).then(() => {
        this.stats = this.processTeamStats(this.team)
        // this.stats = this.formatStats(stats)
      })
    },
    updateTeamData () {
      this.showSignup = false
      this.showTeam = false
      this.loading = true
      this.getTeamDetails(this.team.tsId).then((tsTeamData) => {
        this.syncTsData(this.team, tsTeamData).then((tsResult) => {
          this.team = store.state.team
          this.stats = this.processTeamStats(store.state.team)
          // this.stats = this.formatStats(stats)
          this.buildSummaryFields()
          this.$emit('updateHead')
          this.showTeam = true
          this.doReload = 0
          localStorage.setItem('doReload', 0)
          this.tsResult = tsResult
          this.loading = false
          this.showAlert()
        })
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      alert(this.tsResult)
      // this.dismissCountDown = this.dismissSecs
    },
    scrollTo(target) {
      document.getElementById(target).scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth"
      })
    },
    onPastGameRowClicked (item) {
      this.$router.push({
        name: 'GameDetail',
        params: { gameId: item.id, teamId: this.team.id }
      })
    },
    onFutureGameRowClicked (item) {
      if (this.isManager) {
        this.$router.push({
          name: 'LiveStatsInput',
          params: { gameId: item.id, teamId: this.team.id }
        })
      } else {
        this.$router.push({
          name: 'GameDetail',
          params: { gameId: item.id, teamId: this.team.id }
        })
      }
    },
    view (gameId) {
      this.$router.push({
        name: 'GameDetail',
        params: { gameId: gameId, teamId: this.team.id }
      })
    },
    add (gameId) {
      this.$router.push({
        name: 'GameStatsInput',
        params: { gameId: gameId, teamId: this.team.id }
      })
    },
    goToOpponent (tsOpponentId) {
      this.$router.go({
        name: 'OpponentDetail',
        params: { teamId: this.team.tsId, opponentId: tsOpponentId }
      })
    },
    live (gameId) {
      this.$router.push({
        name: 'LiveStatsInput',
        params: { gameId: gameId, teamId: this.team.id }
      })
    }
  }
}
</script>
