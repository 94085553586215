<script>

import { store } from '../store'
import { orderBy } from 'lodash-es'
import { isEqual } from 'lodash-es'

// import { diff } from 'deep-object-diff'

export default {
  name: 'processGameStats',
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    processGameStats (team, game) {
      return new Promise((resolve) => {
        let original = JSON.parse(JSON.stringify(game))
        let scoring = {
          us: [],
          them: []
        }
        let teamStats = {}
        let statLines = []
        let statWidgets = {}
        let remove = ['powerplays']
        let pointsByPlayer = {}
        let playerStats = []
        let gameScore = []
        let percentageStats = []

        team.statCategories.forEach(stat => {
          if (stat.type === '%') {
            percentageStats.push(stat.name)
          }
        })
        let periods = ['p1', 'p2', 'p3']

        // Clean up scoring

        if ('us' in game.scoring || 'them' in game.scoring) {
          Object.keys(game.scoring).forEach(team => {
            game.scoring[team].forEach(goal => {
              let assists = []
              let onice = []
              // If the period is null, set it to period 1
              if (!goal.period) {
                goal.period = 1
              }
              // Remove blanks from assists by keeping only numbers
              goal.assists.forEach(player => {
                if (typeof player === 'number') {
                  assists.push(player)
                }
              })
              goal.onice.forEach(player => {
                // Only add onice if the player is not the scorer or assist (only for us)
                if (team === 'us') {
                  if (!assists.includes(player) && goal.scored !== player && typeof player === 'number') {
                    onice.push(player)
                  }
                } else {
                  onice.push(player)
                }
              })
              goal.assists = assists
              goal.onice = onice
              scoring[team].push(goal)
            })
          })
        }

        /* eslint-disable no-undef, no-unused-vars, no-redeclare */

        game.scoring = scoring

        // Init statWidget object

        for (const [statName, stat] of Object.entries(game.stats)) {
          statWidgets[stat.name] = {
            name: stat.name,
            total: {
              us: {
                count: 0,
                perc: 0,
              },
              them: {
                count: 0,
                perc: 0,
              }
            },
            p1: {
              us: {
                count: 0,
                perc: 0,
              },
              them: {
                count: 0,
                perc: 0,
              }
            },
            p2: {
              us: {
                count: 0,
                perc: 0,
              },
              them: {
                count: 0,
                perc: 0,
              }
            },
            p3: {
              us: {
                count: 0,
                perc: 0,
              },
              them: {
                count: 0,
                perc: 0,
              }
            }
          }
        }


        for (const [statName, stat] of Object.entries(game.stats)) {
          let statTotal = {
            us: 0,
            them: 0
          }
          periods.forEach(period => {
            statTotal.us += stat.us[period]
            statTotal.them += stat.them[period]

            let statLine = {
              name: stat.name,
              us: stat.us[period],
              them: stat.them[period],
              period: period,
              _showDetails: false,
              order: stat.order + `-` + period
            }
            statLines.push(statLine)
            statWidgets[stat.name][period]['us'].count = stat.us[period]
            statWidgets[stat.name][period]['us'].perc = stat.us[period] / (stat.us[period] + stat.them[period])
            statWidgets[stat.name][period]['them'].count = stat.them[period]
            statWidgets[stat.name][period]['them'].perc = stat.them[period] / (stat.us[period] + stat.them[period])
          })
          let totalStatLine = {
            name: stat.name,
            us: statTotal.us,
            them: statTotal.them,
            period: 'total',
            _showDetails: false,
            order: stat.order + `-total`
          }
          statLines.push(totalStatLine)

          statWidgets[stat.name]['total']['us'].count = statTotal.us
          statWidgets[stat.name]['total']['us'].perc = statTotal.us / (statTotal.us + statTotal.them)
          statWidgets[stat.name]['total']['them'].count = statTotal.them
          statWidgets[stat.name]['total']['them'].perc = statTotal.them / (statTotal.us + statTotal.them)
        }

        // Filter perc statWidgets

        //

        // this.percWidgets = {}


        for (const [statName, stat] of Object.entries(statWidgets)) {
          console.log(stat)
          if (remove.indexOf(stat.name) > -1) {
            delete statWidgets[stat.name]
          }
        }

        gameScore = [
          {
            team: team.name,
            p1: game.stats.goals.us.p1,
            p2: game.stats.goals.us.p2,
            p3: game.stats.goals.us.p3,
            t: 0
          },
          {
            team: game.opponentName,
            p1: game.stats.goals.them.p1,
            p2: game.stats.goals.them.p2,
            p3: game.stats.goals.them.p3,
            t: 0
          }
        ]

        gameScore[0].t = gameScore[0].p1 + gameScore[0].p2 + gameScore[0].p3
        gameScore[1].t = gameScore[1].p1 + gameScore[1].p2 + gameScore[1].p3

        // Itemize the team stats

        teamStats = {
          p1: [
            { team: team.name },
            { team: game.opponentName }
          ],
          p2: [
            { team: team.name },
            { team: game.opponentName }
          ],
          p3: [
            { team: team.name },
            { team: game.opponentName }
          ],
          t: [
            { team: team.name },
            { team: game.opponentName }
          ]
        }

        for (const [statName, stat] of Object.entries(game.stats)) {
          let usTotal = stat.us.p1 + stat.us.p2 + stat.us.p3
          let themTotal = stat.them.p1 + stat.them.p2 + + stat.them.p3
          // Special treatment for percentage stats
          if (percentageStats.includes(stat.name)) {
            teamStats.p1[0][stat.name + 'Perc'] = stat.us.p1 / (stat.us.p1 + stat.them.p1)
            teamStats.p1[1][stat.name + 'Perc'] = stat.them.p1 / (stat.us.p1 + stat.them.p1)
            teamStats.p2[0][stat.name + 'Perc'] = stat.us.p2 / (stat.us.p2 + stat.them.p2)
            teamStats.p2[1][stat.name + 'Perc'] = stat.them.p2 / (stat.us.p2 + stat.them.p2)
            teamStats.p3[0][stat.name + 'Perc'] = stat.us.p3 / (stat.us.p3 + stat.them.p3)
            teamStats.p3[1][stat.name + 'Perc'] = stat.them.p3 / (stat.us.p3 + stat.them.p3)
            teamStats.t[0][stat.name + 'Perc'] = usTotal / (usTotal + themTotal)
            teamStats.t[1][stat.name + 'Perc'] = themTotal / (usTotal + themTotal)
          }
          teamStats.p1[0][stat.name] = stat.us.p1
          teamStats.p1[1][stat.name] = stat.them.p1
          teamStats.p2[0][stat.name] = stat.us.p2
          teamStats.p2[1][stat.name] = stat.them.p2
          teamStats.p3[0][stat.name] = stat.us.p3
          teamStats.p3[1][stat.name] = stat.them.p3
          teamStats.t[0][stat.name] = usTotal
          teamStats.t[1][stat.name] = themTotal
        }

        // Goalie Stats

        // for (const [jerseyNumber, goalie] of Object.entries(game.goalieStats)) {
        //   if (goalie.shots === 0) {
        //     delete game.goalieStats[jerseyNumber]
        //   }
        // }

        for (const [jerseyNumber, goalie] of Object.entries(game.goalieStats)) {
          if (goalie.shots > 0) {
            if (goalie.ga === 0) {
              game.goalieStats[jerseyNumber].svperc = 1
              game.goalieStats[jerseyNumber].so = 1
            } else {
              game.goalieStats[jerseyNumber].svperc = (goalie.shots - goalie.ga) / goalie.shots
            }
          }
        }

        // Individual player stats

        // initialize player object

        console.log(team)

        team.players.forEach(player => {
          pointsByPlayer[parseInt(player.jerseyNumber)] = {}
          pointsByPlayer[parseInt(player.jerseyNumber)].jerseyNumber = player.jerseyNumber
          pointsByPlayer[parseInt(player.jerseyNumber)].name = player.firstName.charAt(0) + '. ' + player.lastName
          pointsByPlayer[parseInt(player.jerseyNumber)].goals = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].assists = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].points = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].plusminus = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].pim = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].ppg = 0
          pointsByPlayer[parseInt(player.jerseyNumber)].shg = 0
        })

        game.penalties.us.forEach(penalty => {
          if (penalty.jerseyNumber > 0) {
            pointsByPlayer[penalty.jerseyNumber].pim += penalty.minutes
          }
        })

        teamStats.p1[0]['powerplaygoals'] = 0
        teamStats.p1[1]['powerplaygoals'] = 0
        teamStats.p2[0]['powerplaygoals'] = 0
        teamStats.p2[1]['powerplaygoals'] = 0
        teamStats.p3[0]['powerplaygoals'] = 0
        teamStats.p3[1]['powerplaygoals'] = 0
        teamStats.t[0]['powerplaygoals'] = 0
        teamStats.t[1]['powerplaygoals'] = 0
        teamStats.p1[0]['shorthandedgoals'] = 0
        teamStats.p1[1]['shorthandedgoals'] = 0
        teamStats.p2[0]['shorthandedgoals'] = 0
        teamStats.p2[1]['shorthandedgoals'] = 0
        teamStats.p3[0]['shorthandedgoals'] = 0
        teamStats.p3[1]['shorthandedgoals'] = 0
        teamStats.t[0]['shorthandedgoals'] = 0
        teamStats.t[1]['shorthandedgoals'] = 0

        game.scoring.us.forEach(goal => {
          if (goal.type !== 'pp' && goal.type != 'en' && goal.type != 'ps') {
            goal.onice.forEach((player) => {
              if (typeof player === 'number') {
                pointsByPlayer[player].plusminus += 1
              }
            })
            if (typeof goal.scored === 'number') {
              pointsByPlayer[goal.scored].plusminus += 1
            }
          }
          goal.assists.forEach(player => {
            if (typeof player === 'number') {
              pointsByPlayer[player].assists += 1
              pointsByPlayer[player].points += 1
              if (goal.type !== 'pp'  && goal.type != 'en' && goal.type != 'ps') {
                pointsByPlayer[player].plusminus += 1
              }
            }
          })
          if (typeof goal.scored === 'number') {
            pointsByPlayer[goal.scored].goals += 1
            pointsByPlayer[goal.scored].points += 1
          }
          if (goal.type === 'pp') {
            teamStats['p' + goal.period][0]['powerplaygoals'] += 1
            teamStats.t[0]['powerplaygoals'] += 1
            if (typeof goal.scored === 'number') {
              pointsByPlayer[goal.scored].ppg += 1
            }
          }
          if (goal.type === 'sh') {
            teamStats['p' + goal.period][0]['shorthandedgoals'] += 1
            teamStats.t[0]['shorthandedgoals'] += 1
            if (typeof goal.scored === 'number') {
              pointsByPlayer[goal.scored].shg += 1
            }
          }
        })

        game.scoring.them.forEach(goal => {
          if (goal.type === 'pp') {
            teamStats['p' + goal.period][1]['powerplaygoals'] += 1
            teamStats.t[1]['powerplaygoals'] += 1
          }
          if (goal.type === 'sh') {
            teamStats['p' + goal.period][1]['shorthandedgoals'] += 1
            teamStats.t[1]['shorthandedgoals'] += 1
          }
          if (goal.type !== 'pp' && goal.type != 'en' && goal.type != 'ps') {
            goal.onice.forEach((player) => {
              if (typeof player === 'number') {
                pointsByPlayer[player].plusminus -= 1
              }
            })
          }
        })

        teamStats.p1[0]['powerplayPerf'] = teamStats.p1[0]['powerplaygoals'] + '/' + teamStats.p1[0]['powerplays']
        teamStats.p1[1]['powerplayPerf'] = teamStats.p1[1]['powerplaygoals'] + '/' + teamStats.p1[1]['powerplays']
        teamStats.p2[0]['powerplayPerf'] = teamStats.p2[0]['powerplaygoals'] + '/' + teamStats.p2[0]['powerplays']
        teamStats.p2[1]['powerplayPerf'] = teamStats.p2[1]['powerplaygoals'] + '/' + teamStats.p2[1]['powerplays']
        teamStats.p3[0]['powerplayPerf'] = teamStats.p3[0]['powerplaygoals'] + '/' + teamStats.p3[0]['powerplays']
        teamStats.p3[1]['powerplayPerf'] = teamStats.p3[1]['powerplaygoals'] + '/' + teamStats.p3[1]['powerplays']
        teamStats.t[0]['powerplayPerf'] = teamStats.t[0]['powerplaygoals'] + '/' + teamStats.t[0]['powerplays']
        teamStats.t[1]['powerplayPerf'] = teamStats.t[1]['powerplaygoals'] + '/' + teamStats.t[1]['powerplays']

        team.players.forEach(player => {
          if (player.position !== 'Goalie') {
            playerStats.push(pointsByPlayer[player.jerseyNumber])
          }
        })

        game.teamStats = teamStats
        game.gameScore = gameScore
        game.playerStats = playerStats
        game.statLines = statLines
        game.statWidgets = statWidgets

        // Ensure stats are ordered properly

        original.statLines = orderBy(original.statLines, ['order'], ['asc'])
        game.statLines = orderBy(game.statLines, ['order'], ['asc'])

        // console.log('diff: ', diff(original, JSON.parse(JSON.stringify(game))))
        // console.log(original, JSON.parse(JSON.stringify(game)))

        if (!isEqual(JSON.parse(JSON.stringify(game)), original)) {
          store.dispatch('saveGame', game).then(() => {
            console.log('Game saved.')
            resolve(game.id)
          })
        } else {
          console.log('Processed without changes')
          resolve(game.id)
        }
      })
    }
  }
}

</script>
